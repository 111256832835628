import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useController } from 'react-hook-form';

import {
  areMailAndPhoneMandatory,
  formSubmitFactory,
  thirdSectorActivities,
  thirdSectorFormFieldNames,
  thirdSectorformIds,
} from './helpers';
import { FieldError } from '../../helpers';

import { provinceOptions } from '../../../../utils/provinces';
import { phoneNumberRegex } from '../../../../utils/regexp';
import Chevron from '../../../../atoms/static-icons/chevron';
import { FormSelect } from '../../../../molecules/form-select/form-select';
import FormDisclaimer from '../../../../molecules/form-discaimer/form-disclaimer';

const ThirdSectorSubform = ({
  watch,
  errors,
  register,
  control,
  setOnSubmit,
  setValue,
  setLoading,
  setShowFeedback,
  reset,
  captcha,
  formId,
  getValues,
}) => {
  const { field: provinceField } = useController({
    control,
    name: thirdSectorFormFieldNames.province,
    rules: {
      required: true,
    },
  });

  const { field: businessScopeField } = useController({
    control,
    name: 'businessScope',
  });

  const [provinceIsInvalid, setProvinceIsInvalid] = useState(false);

  // pass the submit callback up
  useEffect(() => {
    // avoid setting a callback when the form is not ready
    !captcha && setOnSubmit(null);

    setOnSubmit(
      formSubmitFactory({ setLoading, formId, setShowFeedback, reset, captcha })
    );

    // remove the submit callback
    return () => setOnSubmit(null);
  }, [
    setOnSubmit,
    setLoading,
    setShowFeedback,
    reset,
    // ↑  these are stable references
    captcha,
    formId,
  ]);

  const { isEmailMandatory, isPhoneMandatory } =
    areMailAndPhoneMandatory(watch);

  return (
    <>
      <div className='landing-form__field'>
        <label
          className='landing-form__field-label'
          htmlFor={thirdSectorformIds['third-sector__business-name']}
        >
          Ragione Sociale Associazione/Ente*
        </label>
        <input
          placeholder='Ragione Sociale Associazione/Ente*'
          id={thirdSectorformIds['third-sector__business-name']}
          className={classNames('landing-form__field-input', {
            'landing-form__field-input--invalid':
              errors[thirdSectorFormFieldNames.companyName]?.message,
          })}
          {...register(thirdSectorFormFieldNames.companyName, {
            required: 'Campo obbligatorio',
          })}
        ></input>
        <FieldError
          message={errors[thirdSectorFormFieldNames.companyName]?.message}
        />
      </div>
      <div className='landing-form__field'>
        <label
          className='landing-form__field-label'
          htmlFor={thirdSectorformIds['third-sector__town']}
        >
          Comune*
        </label>
        <input
          placeholder='Comune*'
          id={thirdSectorformIds['third-sector__town']}
          className={classNames('landing-form__field-input', {
            'landing-form__field-input--invalid':
              errors[thirdSectorFormFieldNames.city]?.message,
          })}
          {...register('city', {
            required: 'Campo obbligatorio',
          })}
        ></input>
        <FieldError message={errors[thirdSectorFormFieldNames.city]?.message} />
      </div>
      <div className='landing-form__field'>
        <label
          className='landing-form__field-label'
          htmlFor={thirdSectorformIds['third-sector__province']}
        >
          Provincia sede legale*
        </label>
        <FormSelect
          components={{
            DropdownIndicator: Chevron,
          }}
          field={provinceField}
          placeholder='Provincia sede legale*'
          id={thirdSectorformIds['third-sector__province']}
          options={provinceOptions}
          onChange={(newValue) => {
            provinceField.onChange(newValue.value);
          }}
          className={classNames(
            'landing-form__field-input',
            'landing-form__field-select'
          )}
          invalidClassName={'landing-form__field-input--invalid'}
          selectInvalidCB={(isInvalid) => setProvinceIsInvalid(isInvalid)}
        />
        <FieldError message={provinceIsInvalid ? 'Campo obbligatorio' : ''} />
      </div>
      <div className='landing-form__field'>
        <label
          className='landing-form__field-label'
          htmlFor={thirdSectorformIds['third-sector__activity-type']}
        >
          Ambito di attività
        </label>
        <FormSelect
          field={businessScopeField}
          components={{
            DropdownIndicator: Chevron,
          }}
          placeholder='Ambito di attività'
          id={thirdSectorformIds['third-sector__activity-type']}
          options={thirdSectorActivities}
          onChange={(newValue) => {
            businessScopeField.onChange(newValue.value);
          }}
          className={classNames(
            'landing-form__field-input',
            'landing-form__field-select'
          )}
        />
      </div>
      <div className='landing-form__field'>
        <label
          className='landing-form__field-label'
          htmlFor={thirdSectorformIds['third-sector__mail']}
        >
          Email Associazione**
        </label>
        <input
          type={'email'}
          placeholder={`Email Associazione**`}
          id={thirdSectorformIds['third-sector__mail']}
          className={classNames('landing-form__field-input', {
            'landing-form__field-input--invalid':
              errors[thirdSectorFormFieldNames.email]?.type === 'pattern',
          })}
          {...register(thirdSectorFormFieldNames.email, {
            required: isEmailMandatory,
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'Inserisci un formato di email valido',
            },
            onChange: () =>
              setValue(
                thirdSectorFormFieldNames.lastEditedPseudoMandatoryField,
                thirdSectorFormFieldNames.email
              ),
          })}
        ></input>
        <FieldError
          message={errors[thirdSectorFormFieldNames.email]?.message}
        />
      </div>
      <div className='landing-form__field'>
        <label
          className='landing-form__field-label'
          htmlFor={thirdSectorformIds['third-sector__phone']}
        >
          Telefono Associazione (referente commerciale)**
        </label>
        <input
          placeholder={`Recapito telefonico Associazione**`}
          id={thirdSectorformIds['third-sector__phone']}
          className={classNames('landing-form__field-input', {
            'landing-form__field-input--invalid':
              errors[thirdSectorFormFieldNames.phoneNumber]?.type === 'pattern',
          })}
          {...register(thirdSectorFormFieldNames.phoneNumber, {
            required: isPhoneMandatory,
            pattern: {
              value: phoneNumberRegex,
              message: 'Inserisci un numero di telefono valido',
            },
            onChange: () =>
              setValue(
                thirdSectorFormFieldNames.lastEditedPseudoMandatoryField,
                thirdSectorFormFieldNames.phoneNumber
              ),
          })}
        ></input>
        <FieldError
          message={errors[thirdSectorFormFieldNames.phoneNumber]?.message}
        />
      </div>
      <FormDisclaimer
        className='landing-form__disclaimer'
        additionalMessages={['** Compilare almeno uno di questi campi']}
      ></FormDisclaimer>
    </>
  );
};

export default ThirdSectorSubform;
